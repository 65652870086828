.box {
  $this: &;

  &__index-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 200px;
    min-height: 99px;
    padding: 10px;
    border: 2px solid;
    border-radius: 10px;

    &_inactive {
      border: 2px dashed #bdbdbd !important;
    }
  }

  &__item-icon {
    width: 30px;
    height: 30px;

    &_inactive {
      color: #828282 !important;
    }
  }

  &__children-wrapper {
    width: 200px;
  }
}
