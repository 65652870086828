.legalesign-signer {
  $width-label: 140px;

  &__details {
    margin-top: 0.5rem;
  }

  &__label {
    width: $width-label;
    margin-bottom: 0.5rem;
  }

  &__info {
    width: calc(100% - #{$width-label});
  }
}
