.filter-item {
  padding: 0.25rem 0.5rem !important;
  font-size: 85%;
  outline: none;
  cursor: pointer;

  &.active,
  &:active {
    color: #16181b !important;
    background-color: rgba(0, 123, 255, 0.12) !important;
  }

  &__label {
    font-size: 90%;
  }
}
