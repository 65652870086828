.multi-select-filter {
  width: 100%;
  text-align: left;

  &__toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 7px;
    color: #6c757d;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;

    &_active {
      color: #007bff;
    }
  }

  &__menu {
    flex-direction: column;
    min-width: 250px !important;
    max-height: 250px;
    padding: 0 !important;
    overflow: hidden;

    &.show {
      display: flex !important;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: space-between;
    height: 50px;
  }

  &__options {
    flex-grow: 1;
    overflow-y: scroll;
  }

  &__clear {
    flex-shrink: 0;
    font-size: inherit !important;

    &_hidden {
      display: none;
    }

    &:hover {
      color: #212529;
    }
  }
}
