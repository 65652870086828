.visitor-page {
  &__list,
  &__statistics {
    padding: 1rem 0 0;
    font-size: 80%;
  }

  .nav-link {
    cursor: pointer;
  }
}
