.visitor-instance-list {
  &__icon {
    color: #6b6b6b;

    &_active {
      color: #007bff;
      opacity: 0.7;
    }

    opacity: 0.1;
  }

  &__row {
    cursor: pointer;

    &_selected {
      background-color: rgba(0, 123, 255, 0.18) !important;
    }
  }
}
