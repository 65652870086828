.file-upload {
  &__files {
    position: relative;
    width: 100%;
    font-size: 80%;
  }

  &__file-name {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__list {
    max-height: 280px;
    overflow: scroll;
  }

  &__file,
  &__files-header {
    display: flex;
    flex-direction: row;
    min-height: 35px;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
    margin-top: -1px;
    color: rgba(0, 0, 0, 0.3);
    text-align: center;
    border: 1px solid #dee2e6;
    outline: none;
    cursor: pointer;

    & > input {
      position: absolute;
      z-index: -1;
      width: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
    }

    &_disabled {
      display: none;
    }

    &_active {
      background: rgba(0, 123, 255, 0.1);
      border-color: rgba(0, 123, 255, 0.48);
    }
  }
}
