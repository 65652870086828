.request-list {
  &__details {
    padding: 1rem 35px;
    border-top: 1px solid rgba(0, 0, 0, 0.02);
  }

  &__indicator {
    display: none;
    background: none;
    border: none;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }

    & .fa {
      pointer-events: none;
    }
  }

  &__row {
    &_unread {
      font-weight: 600;
    }

    &:hover {
      .request-list__indicator {
        display: initial;
      }
    }
  }
}
