.page {
  flex-grow: 1;
  width: 100%;
  padding: 1rem;

  &__container {
    height: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__content {
    padding: 0 0 1em 0;

    &_fixed {
      padding: 0;
      overflow-y: auto;
    }
  }
}
