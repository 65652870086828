$error_color: #dc3545;

.form {
  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
  }

  &__footer {
    width: 100%;
  }
}

.form-field {
  &__label {
    font-size: 0.8em;
  }
}

.custom-control {
  line-height: 22px;
}

.is-invalid {
  .field-select {
    &__control {
      border-color: $error_color !important;

      &--is-focused {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
      }
    }
  }
}
