.graph {
  &__edges,
  &__nodes-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.2s ease;
  }

  &__nodes-container {
    background: #202020;
    cursor: all-scroll;
  }

  &__nodes {
    transform-origin: 0 0;

    &-modal.modal-dialog {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      margin: 0;
    }

    &-modal.modal-dialog .modal-content {
      position: static;
      height: 100%;
      border: 0;
    }
  }

  &__edge {
    opacity: 0.2;
    fill: transparent;
    stroke: white;

    &:hover {
      opacity: 1;
      stroke-width: 1px;
    }
  }

  &__node.card {
    position: absolute;
    width: 300px;
  }

  &__canvas-controls.btn-group {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  /* For some reason it seems bootstrap adds a left margin to btn + btn even in vertical btn groups */
  &__canvas-controls .btn + .btn {
    margin-left: 0;
  }
}
