.notification {
  width: 290px;

  &__header {
    position: relative;
    min-height: 29px;
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    font-size: inherit !important;
    outline: none !important;
    transform: translateY(-50%);

    &:not(:hover) {
      opacity: 0.3 !important;
    }
  }

  &_type {
    &_error {
      border-color: rgba(220, 53, 69, 0.2) !important;
    }

    &_success {
      border-color: rgba(40, 167, 69, 0.2) !important;
    }

    &_info {
      border-color: rgba(0, 123, 255, 0.2) !important;
    }
  }

  &:hover {
    .notification__button {
      display: inline;
    }
  }
}
