.select-filter {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2) !important;
  }

  &_active {
    color: #007bff;
  }
}
