.instance-details {
  $width-label: 140px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  padding: 1rem 0;

  &__actions {
    flex-basis: 190px;
    padding: 0 0.5rem;

    & .btn {
      width: 100%;
      margin: 0 0 0.5rem 0 !important;
    }
  }

  &__details {
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 0.5rem;
  }

  &__label {
    width: $width-label;
    margin-bottom: 0.5rem;
  }

  &__info {
    width: calc(100% - #{$width-label});
  }

  &__features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__signature {
    flex-basis: 300px;
    margin: 0 0 1rem 0;
  }

  &__production {
    flex-basis: 350px;
  }
}
