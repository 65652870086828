/* Override react-table */
.ReactTable {
  & .rt-tr {
    align-items: center;
  }

  & .rt-tr-group {
    flex-grow: 0 !important;
  }
}
