.json-viewer {
  & ul {
    font-size: 13px;
    background: none !important;
  }

  & label {
    font-weight: 600 !important;
  }
}
