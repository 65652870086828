.navigation {
  $this: &;

  &__group {
    position: relative;
    display: flex;

    &::after {
      position: absolute;
      top: 50%;
      right: 12px;
      left: 12px;
      display: block;
      height: 1px;
      background-color: #bdbdbd;
      transform: translateY(-50%);
      content: '';
    }
  }

  &__group-name {
    z-index: 10;
    padding: 0 0.5rem;
    font-weight: 700;
    background-color: #fff;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }

  &__nav-link {
    position: relative;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    height: 50px;

    &.active {
      font-weight: 700;

      & #{$this}__indicator {
        opacity: 1;
      }
    }
  }

  &__indicator {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
    width: 4px;
    border-radius: 0 4px 4px 0;
    opacity: 0;
    transition: opacity 0.5s;
    content: '';
  }
}
