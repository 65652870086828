$google-color: #db4437;
$google-color-hover: #c7372d;

$facebook-color: #4267b2;
$facebook-color-hover: #365899;

.social-auth {
  &__button {
    margin: 0 1em;
    color: #fff;

    &_type_facebook {
      background-color: $facebook-color !important;
      border-color: $facebook-color !important;

      &:hover {
        background-color: $facebook-color-hover !important;
        border-color: $facebook-color-hover !important;
      }
    }

    &_type_google {
      background-color: $google-color !important;
      border-color: $google-color !important;

      &:hover {
        background-color: $google-color-hover !important;
        border-color: $google-color-hover !important;
      }
    }
  }
}
