@import 'react-table';

.app-badge {
  display: inline-block;
  margin: 0.15rem 0.25rem 0.15rem 0;
  padding: 0.35rem 0.45rem;
  font-size: 70%;
  line-height: 1;
  background-color: #eee;
  border: none;
  border-radius: 4px;
  outline: none !important;
  cursor: pointer;

  &_main {
    $color: #007bff;

    color: $color;
    background-color: rgba($color, 0.12);

    &:hover {
      background-color: rgba($color, 0.17);
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem rgba($color, 0.5);
    }
  }

  &_purple {
    $color: #722ed1;

    color: $color;
    background-color: rgba($color, 0.12);

    &:hover {
      background-color: rgba($color, 0.17);
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem rgba($color, 0.5);
    }
  }
}

.app-entity-list {
  max-height: 100%;
  font-size: 80%;

  &__cell {
    &_badge {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-self: flex-start;
      padding: 0.5rem;
    }
  }

  &__form {
    padding: 1rem 1rem 1rem 42px;
    border-top: 1px solid rgba(0, 0, 0, 0.02);
  }
}

.app-page-content {
  height: calc(100% - 64px);
}

.app-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
}
