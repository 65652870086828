.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 150px;

  &__spinner {
    display: block !important;
    margin: 0 auto;
  }
}
