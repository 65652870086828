.icon {
  display: inline-block;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: inherit;
  vertical-align: middle;
  background-size: 24px 24px;
  pointer-events: none;

  svg {
    vertical-align: initial;
  }
}
