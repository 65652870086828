.auth-pages-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 10px 0;
  background: var(--light, #f8f9fa);

  &__content {
    width: calc(100% - 20px);
    max-width: 350px;
    margin: 0 auto;
  }

  &__logo {
    display: block;
    max-width: 50%;
    margin: -20px auto;
  }
}
