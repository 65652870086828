.request-details {
  &__label {
    width: 125px;
    margin-bottom: 0.5rem;
  }

  &__info {
    width: calc(100% - 125px);
  }

  &__controls {
    & > .btn {
      margin: 0 0.5rem 0.5rem 0 !important;
    }
  }
}
