.plot-form {
  &__project,
  &__status {
    &_invalid {
      ~ .invalid-feedback {
        display: block;
      }
    }
  }

  &__buttons {
    &_hidden {
      display: none;
    }
  }
}
