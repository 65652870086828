.breadcrumb-item {
  @media (max-width: 575px) {
    display: none;

    &:last-child {
      display: initial;
    }
  }

  & + .breadcrumb-item {
    position: relative;
    padding-left: 1rem !important;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}
