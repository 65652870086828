.table {
  & thead th {
    vertical-align: top !important;
  }

  & td {
    vertical-align: middle !important;

    & p {
      margin: 0;
    }
  }

  &__column-header {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__column-sort-icon {
    display: inline;
    margin-right: 5px;
    color: var(--gray);

    &_active {
      color: inherit;
    }
  }
}
